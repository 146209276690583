.login-box {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	padding: 40px;
	transform: translate(-50%, -50%);
	background: rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
	border-radius: 10px;
}

.backgroundVideo {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	bottom: 0;
	object-fit: cover;
}

.login-box h2 {
	margin: 0 0 30px;
	padding: 0;
	color: #fff;
	text-align: center;
}

.login-box .user-box {
	position: relative;
}

.login-box .user-box input {
	width: 100%;
	padding: 10px 0;
	font-size: 16px;
	color: #fff;
	margin-bottom: 30px;
	border: none;
	border-bottom: 1px solid #fff;
	outline: none;
	background: transparent;
}
.login-box .user-box label {
	position: absolute;
	top: 0;
	left: 0;
	padding: 10px 0;
	font-size: 16px;
	color: #fff;
	pointer-events: none;
	transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
	top: -20px;
	left: 0;
	/* color: #03e9f4; */
	font-size: 12px;
}

/* .login-box form a {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	color: #fff;
	font-size: 16px;
	text-decoration: none;
	text-transform: uppercase;
	overflow: hidden;
	transition: 0.5s;
	margin-top: 40px;
	letter-spacing: 4px;
} */

.submit {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	color: #fff;
	font-size: 16px;
	text-decoration: none;
	text-transform: uppercase;
	overflow: hidden;
	transition: 0.5s;
	margin-top: 40px;
	letter-spacing: 4px;
}

.submit:hover {
	/* background: #03e9f4; */
	background-color: #fff;
	/* color: #000; */
	border-radius: 5px;
	box-shadow: 0 0 5px #fff, 0 0 25px #fff, 0 0 50px #fff, 0 0 100px #fff;
}

.submit span {
	position: absolute;
	display: block;
}

.submit span:nth-child(1) {
	top: 0;
	left: -100%;
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, transparent, #fff);
	animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
	0% {
		left: -100%;
	}
	50%,
	100% {
		left: 100%;
	}
}

.submit span:nth-child(2) {
	top: -100%;
	right: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(180deg, transparent, #fff);
	animation: btn-anim2 1s linear infinite;
	animation-delay: 0.25s;
}

@keyframes btn-anim2 {
	0% {
		top: -100%;
	}
	50%,
	100% {
		top: 100%;
	}
}

.submit span:nth-child(3) {
	bottom: 0;
	right: -100%;
	width: 100%;
	height: 2px;
	background: linear-gradient(270deg, transparent, #fff);
	animation: btn-anim3 1s linear infinite;
	animation-delay: 0.5s;
}

@keyframes btn-anim3 {
	0% {
		right: -100%;
	}
	50%,
	100% {
		right: 100%;
	}
}

.submit span:nth-child(4) {
	bottom: -100%;
	left: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(360deg, transparent, #fff);
	animation: btn-anim4 1s linear infinite;
	animation-delay: 0.75s;
}

.sign {
	color: #fff;
	text-decoration: none;
}

.sign:hover {
	color: #fff;
}

@keyframes btn-anim4 {
	0% {
		bottom: -100%;
	}
	50%,
	100% {
		bottom: 100%;
	}
}
