.footer__section {
	background-color: #000;
	height: 170px;
	padding: 30px;
	margin-top: 100px;
	color: #fff;
}

.footer {
	color: #fff !important;
}

/* .footer__nav {
	list-style: none;
	font-size: 18px;
	margin: 0;
	padding: 0;
}

.footer__nav-item {
	text-align: center;
}

.footer__nav-item a {
	text-decoration: none;
	color: #fff;
} */


