.content__movies {
	background: #000;
	max-width: 1200px;
	margin: 0 auto;
	padding: 30px 60px;
}

.categories {
	margin: 0 auto;
}

.categories__container {
}

.categories__list {
	list-style: none;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0;
	padding: 0;
	height: 75px;
	font-size: 20px;
}

.categories__list-item {
	width: 100%;
	text-align: center;
}

.categories__list-item a {
	text-decoration: none;
	color: #fff;
	cursor: pointer;
	display: block;
	width: 100%;
	transition: 0.3s;
}
.categories__list-item a:hover {
	color: #000;
	background: #fff;
}

.categories__list-item.active a {
	color: #000;
	background: #fff;
}

.movies {
	margin: 50px auto 0 auto;
	transition: 0.5s;
}

.movies-heading {
	font-size: 30px;
	color: #fff;
	margin: 0;
	padding: 0;
	margin-bottom: 50px;
}

.movies__container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	/* justify-content: center; */
	gap: 15px;
}

.movies__container-item {
	width: 250px;
	height: 340px;
	opacity: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.movies__container-item:hover {
	transform: scale(1.1);
	opacity: 50%;
}
.movies__container-item-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	background: linear-gradient(to bottom, transparent 50%, black 100%);
	display: flex;
	justify-content: center;
	color: #fff;
	transition: 0.3s;
}

.movies__container-item-wrapper:hover {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: center;
	background: linear-gradient(to bottom, transparent 0%, black 100%);
	display: flex;
	justify-content: center;
	color: #fff;
	transition: 0.3s;
}

.movie__container_item-title {
	position: absolute;
	bottom: 20px;
}

@media screen and (max-width: 500px) {
	.content__movies {
		padding: 0;
	}
}
