.header__main {
	padding: 10px 35px;
	background-color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
	/* transition: 0.5s; */
	background-color: transparent;
	transition: background-color 0.1 ease-in-out;
}

.header__main a {
	color: #fff;
	text-decoration: none;
}

header.scrolled {
	background-color: #000; /* Change to your desired color */
}

.header__main__logo a {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-decoration: none;
}

.header__main__logo a:hover {
	text-decoration: none;
	color: #fff;
}

.header__main__search input {
	width: 100%;
	height: 30px;
	border-radius: 5px;
	border: none;
	padding: 0 10px;
	outline: none;
}

.header__main__user {
	display: flex;
	gap: 20px;
}

.header__main__user a:hover {
	text-decoration: none;
	color: #fff;
}

button.btn {
	/* width: 100%; */
	height: 30px;
	border-radius: 5px;
}

/* .header__main__user a {
} */

.header__main__nav {
	max-width: 50%;
	padding-top: 17px;
}

.header__main__nav__list {
	display: flex;
	list-style: none;
}

.header__main__nav__list li a {
	color: #fff;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	padding: 0 20px;
}

@media screen and (max-width: 920px) {
	.header__main {
		padding: 10px 20px;
	}

	.header__main__nav {
		display: none;
	}

	.header__main__search {
		display: none;
	}

	.header__main__logo a {
		font-size: 16px;
	}

	.header__main__user {
		display: none;
	}
}
