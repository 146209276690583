.search__content {
	background: #000;
	max-width: 1200px;
	margin: 100px auto 0 auto;
	padding: 30px 60px;
	color: #fff;
}

.search {
}

input.search__item {
	width: 90%;
	display: block;
	padding: 8px 12px;
	outline: none;
	margin-bottom: 10px;
}

.alertBox.active {
	visibility: visible !important;
}

input.collection_name {
	display: none;
}
input.collection_name.active {
	display: unset;
}

.collection_old.active {
	display: unset;
}

.collection_old {
	display: none;
}

.collection_editing {
	display: none;
}

.collection_editing.active {
	display: unset;
}

td,
th {
	border: 1px solid #fff;
	padding: 8px;
	text-align: left;
}

input {
	outline: none;
}
