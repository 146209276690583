.detail-page {
	margin-top: 77px;
	color: #fff;
	padding: 20px 35px;
	max-width: 1140px;
}

.additional_info {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-bottom: 10px;
}

.rating {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-bottom: 10px;
}

.transparent {
	background: #000;
	color: #fff;
	border: 1px solid #fff;
}

.video__container {
	display: none;
	overflow: hidden;
}

.video__container video {
	/* position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; */
	overflow: hidden;
	z-index: 999;
}

.video__container.active {
	display: block;
	margin: 0 auto;
}

.video__container.active video {
	width: 100%;
	height: 100%;
}

.watch {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	color: #fff;
	font-size: 16px;
	text-decoration: none;
	text-transform: uppercase;
	overflow: hidden;
	transition: 0.5s;
	margin-top: 40px;
	letter-spacing: 4px;
}

.watch:hover {
	/* background: #03e9f4; */
	background-color: #fff;
	/* color: #000; */
	border-radius: 5px;
	box-shadow: 0 0 5px #fff, 0 0 25px #fff, 0 0 50px #fff, 0 0 100px #fff;
}

.reply__block.active {
	display: flex !important;
}

.season-item.active {
	background-color: #fff;
	color: #000;
}

.video-container {
	position: relative;
	width: 400px; /* Adjust the width as needed */
}

.video-item {
	width: 100%;
	z-index: 100;
}

.image-preview {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1; /* Adjust the opacity as needed */
	transition: 0.5s;
}

.image-preview:hover {
	opacity: 0;
	transition: 0.5s;
}
