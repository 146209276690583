@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

fieldset,
label {
	margin: 0;
	padding: 0;
}
body {
	margin: 20px;
}
h1 {
	font-size: 1.5em;
	margin: 10px;
}

/****** Style Star Rating Widget *****/

.rating__input {
	border: none;
	float: left;
}

.rating__input > input {
	display: none;
}
.rating__input > label:before {
	margin: 5px;
	font-size: 1.25em;
	font-family: FontAwesome;
	display: inline-block;
	content: "\f005";
}

.rating__input > .half:before {
	content: "\f089";
	position: absolute;
}

.rating__input > label {
	color: #ddd;
	float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating__input > input:checked ~ label, /* show gold star when clicked */
.rating__input:not(:checked) > label:hover, /* hover current star */
.rating__input:not(:checked) > label:hover ~ label {
	color: #ffd700;
} /* hover previous stars in list */

.rating__input > input:checked + label:hover, /* hover current star when changing rating */
.rating__input > input:checked ~ label:hover,
.rating__input > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating__input > input:checked ~ label:hover ~ label {
	color: #ffed85;
}
