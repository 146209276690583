.collections {
	color: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-bottom: 50px;
}

.collections-heading {
	text-align: center;
}

.collections__content {
	width: 100%;
	padding: 20px 40px;
}

.content-item-heading {
	font-size: 24px;
	margin-bottom: 15px;
}

.collections__movies {
	padding: 30px 0;
	display: flex;
}

.collections__movies-item {
	width: 275px;
	height: 370px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	transition: 0.5s;
	color: #fff;
	margin-right: 15px;
	overflow: hidden;
}

.collections__movies-item:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 100;
}

.collections__movies-item-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 100%;
	text-align: center;
	background: linear-gradient(to bottom, transparent 0%, black 100%);
	display: flex;
	justify-content: center;
	color: #fff;
	transition: 0.5s;
}

.collections__movies-item-wrapper:hover {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 50%;
	text-align: center;
	background: linear-gradient(to bottom, transparent 0%, black 100%);
	display: flex;
	justify-content: center;
	color: #fff;
	transition: 0.5s;
}

.movie__link {
	text-decoration: none;
	color: #fff;
	display: block;
	width: 300px;
	height: 400px;
}

.movie__link:hover {
	text-decoration: none;
	color: #fff;
}
.title {
	display: block;
	position: absolute;
	bottom: 20px;
}

.collections__movies-item-title {
	z-index: 1000;
	position: absolute;
	color: #fff;
	font-style: 24px;
	text-align: center;
	width: 100%;
	bottom: 20px;
	transition: 0.5s;
}
